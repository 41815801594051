import React, { useState, useEffect, useRef } from 'react';
import { FormWrapper } from './FormWrapper.tsx';
import axios from 'axios';
import style from './UploadChats.module.css'


type UploadData = {
    audioFile: File | null,
};

type AudioUploadFormProps = UploadData & {
    updateFormData: (fields: Partial<UploadData>) => void,
};

export function UploadAudio({ audioFile, updateFormData }: AudioUploadFormProps) {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }, [audioFile]);

    
    return (
        <FormWrapper title="Upload audio files">
            <p style={{}}>Important Note: Please upload audio files of the person whose voice you wish to clone. The audio should feature the person speaking naturally, as this will ensure the best results for voice cloning. Try to avoid uploading files with background noise or unrelated content.</p>
            <input ref={fileInputRef} onChange={(e) => updateFormData({ audioFile: e.target.files ? e.target.files[0] : null })} type="file" id="audioFile" name="audioFile" />
            <label className={style.uploadButton} htmlFor="audioFile">Choose an audio file</label>
            {audioFile && <p>Selected file: {audioFile.name}</p>}
        </FormWrapper>
        
    );
}
