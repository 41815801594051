import React from "react"
import { useState } from "react"
import axios from "axios"
import { MultistepForm } from "./MultistepForm.tsx"
import style from './UploadChats.module.css'

const UploadChatsContainer = (props) => {

    const [file, setFile] = useState(null)
    const [msg, setMsg] = useState(null)

    function handleUpload() {
        if (!file) {
            setMsg("No file selected")
            return;
        }

        if (file.size > 1048576*100) {
            setMsg("File size exceeds 100MB")
            return;
        }

        if (file.type !== "application/zip") {
            setMsg("Only zip files are allowed")
            return;
        }

        const fd = new FormData()
        fd.append('file', file)
        setMsg("Uploading...")

        axios.post('https://kai-tech.org/uploadchats', fd, {
            onUploadProgress: (progressEvent) => {
                console.log('Upload progress: ' + Math.round(progressEvent.loaded / progressEvent.total * 100));
        }, headers: {
            "Custom-Header": "value",
        }})
        .then(res => {
            setMsg("Uploaded successfully");
            setTimeout(() => {
                setMsg(null);
            }, 3000);
        })
        .catch(err => {
            setMsg("Upload failed");
            console.error(err)
        });
    }

    return (
        <div style={{marginTop: "100px"}}>
            <div className={style.headerContainer}>
                Clone a person from your chat
            </div>

            {msg && <p>{msg}</p>}

            <MultistepForm />

        </div>
    )
}

export default UploadChatsContainer;