import { ReactNode } from 'react'
import React from 'react'
import style from './UploadChats.module.css'

type FormWrapperProps = {
    title: string,
    children: ReactNode
}

export function FormWrapper({ title, children }: FormWrapperProps) {
    return(
        <>
            <h2 style={{textAlign: "center", margin: 0, marginBottom: "2rem"}}>{title}</h2>
            <div className={style.formWrapper}>{children}</div>
        </>
    )
}