import React, { useState } from 'react'
import { FormWrapper } from './FormWrapper.tsx'
import { User } from '@azure/cosmos'
import style from './UploadChats.module.css'

type UserData = {
    name: string,
    phoneNumber: string,
    gender: string,
    dateofbirth: string,
}

type UserFormProps = UserData &{
    updateFormData: (fields: Partial<UserData>) => void,
}

export function UserForm({ updateFormData }: UserFormProps) {
    const [formData, setFormData] = useState<UserData>({
        name: '',
        phoneNumber: '',
        gender: '',
        dateofbirth: ''
    });

    const handleChange = (field: keyof UserData) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value;
        setFormData(prev => {
            const newData = { ...prev, [field]: value };
            updateFormData(newData);
            return newData;
        });
    };

    return (
        <FormWrapper title='Your information'>
            <div>
                <p style={{marginBottom: "2px"}} >Add the name of the person you are cloning</p>
                <label>Persona name</label>
                <input
                    className={style.inputField}
                    placeholder='name'
                    required
                    type="text"
                    value={formData.name}
                    onChange={handleChange('name')}
                />
            </div>
            {formData.name && (
                <div>
                    <p style={{marginBottom: "-10px"}}>Add YOUR phone number that you will use to call your persona.</p>
                    <p style={{marginBottom: "1px"}}>We support ONLY US phone numbers at this time.</p>
                    <label>Phone Number</label> 
                    <input
                        className={style.inputField}
                        placeholder='123-456-7890'
                        required
                        type="number"
                        value={formData.phoneNumber}
                        onChange={handleChange('phoneNumber')}
                    />
                </div>
            )}
            {formData.phoneNumber && (
                <div>
                    Gender
                    <label>
                        <input
                            className={style.inputField}
                            type="radio"
                            value="m"
                            checked={formData.gender === 'm'}
                            onChange={handleChange('gender')}
                        />
                        Male
                    </label>
                    <label>
                        <input
                            className={style.inputField}
                            type="radio"
                            value="f"
                            checked={formData.gender === 'f'}
                            onChange={handleChange('gender')}
                        />
                        Female
                    </label>
                </div>
            )}
            {formData.gender && (
                <div>
                    Date of Birth
                    <input
                        className={style.inputField}
                        required
                        type="date"
                        value={formData.dateofbirth}
                        onChange={handleChange('dateofbirth')}
                    />
                </div>
            )}
        </FormWrapper>
    );
}