import React from 'react';
import style from './ProfileComponent.module.css';
import { NavLink } from 'react-router-dom';




const ProfileComponent = () => {

    return (
        <div className={style.bodyElement}>
            <div className={style.text}>
                <p>Welcome to Your Profile!</p>
                <p>Thanks for joining us on this exciting journey!</p> 
                <p>We're a new startup, and we're rapidly developing the app, so expect frequent updates with new features and improvements.</p>
                <p>Your feedback is crucial in helping us grow, so feel free to share your thoughts anytime at kaiiitech000@gmail.com</p>
                <p>Stay tuned for more updates—we're just getting started!</p>
            </div>
            <div>
                <NavLink to="/upload-chats">
                    <button className={style.buttonUploadChats}>Upload Chats</button>
                </NavLink>
            </div>
        </div>
    )};

export default ProfileComponent;