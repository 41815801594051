import React from "react";
import ProfileComponent from "./ProfileComponent";



const ProfileComponentContainer = (props) => {
    return (
        <ProfileComponent />
    );
}

export default ProfileComponentContainer;