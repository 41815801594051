import React from "react"
import HomeContainer from "../../components/Home/HomeContainer"

const HomePage = (props) => {

    return (
            <HomeContainer />
    );
}



export default HomePage;