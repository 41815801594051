import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import authReducer from './reducers/authReducer';


const rootReducer = combineReducers({
    auth: authReducer 
  });

export default configureStore({ reducer: rootReducer }); 

