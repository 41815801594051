import React, { useState } from "react"
import { useMultistepForm } from "./useMultistepForm.ts"
import { UserForm } from "./UserForm.tsx"
import { NewForm } from "./NewForm.tsx"
import { UploadForm } from "./UploadForm.tsx"
import { UploadAudio } from "./UploadAudio.tsx"
import axios from "axios"
import style from './UploadChats.module.css'

type FormData = {
    name: string,
    gender: string,
    dateofbirth: string,
    otherName: string,
    info: string,
    file: File | null,
    audioFile: File | null,
    chatType: string,
}

const INITIAL_FORM_DATA: FormData = {
    name: "",
    gender: "",
    dateofbirth: "",
    otherName: "",
    info: "",
    file: null,
    audioFile: null,
    chatType: "",

}

export function MultistepForm() {

    const [formData, setFormData] = useState(INITIAL_FORM_DATA)
    const [msg, setMsg] = useState<string | null>(null)

    function updateFormData(fields: Partial<FormData>) {
        setFormData(prev => {
            return {...prev, ...fields }
        })
    }

    const { steps, currentStepIndex, next, back, goTo, isFirstStep, isLastStep } = useMultistepForm([
        <UserForm {...formData} updateFormData={updateFormData} />,
        <UploadForm {...formData} updateFormData={updateFormData} />,
        <UploadAudio {...formData} updateFormData={updateFormData} />,
    ])

    function onSubmit(e: React.FormEvent) {
        e.preventDefault()
        if (isLastStep) {
            handleSubmit()
        } else {
            next()
        }
    }

    async function handleSubmit() {
        if (formData.file) {
            if (formData.file.size > 1048576 * 100) {
                setMsg("Chat zip file size exceeds 100MB");
                return;
            }
            if (formData.file.type !== "application/zip") {
                setMsg("Only zip files are allowed for chat sample");
                return;
            }
        }

        if (formData.audioFile) {
            if (formData.audioFile.size > 1048576 * 10) {
                setMsg("Audio file size exceeds 10MB");
                return;
            }
            const allowedAudioTypes = ["audio/mpeg", "audio/wav", "audio/ogg"];
            if (!allowedAudioTypes.includes(formData.audioFile.type)) {
                setMsg("Only audio files (mp3, wav, ogg) are allowed for voice samples");
                return;
            }
        }

        setMsg("Uploading...");

        const fd = new FormData();
        for (const key in formData) {
            if (key === "file" || key === "audioFile") {
                if (formData[key]) {
                    fd.append(key, formData[key]);
                }
            } else {
                fd.append(key, formData[key] as string);
            }
        }

        try {
            const response = await axios.post('https://kai-tech.org/persona_creation_form', fd);
            console.log(response.data);
            setMsg("Form submitted successfully");
            setFormData(INITIAL_FORM_DATA);
            goTo(0);
        } catch (error) {
            console.error('Error submitting form data:', error);
            setMsg("Error submitting form data:" + " " + error.response.data.message);
        }
    }

    return (
        <div className={style.container}>
            <form onSubmit={onSubmit}>
                <div className={style.stepCounter}>
                    {currentStepIndex + 1 } / {steps.length}
                </div>
                {steps[currentStepIndex]}
                <div className={style.formControlButtons}>
                    { !isFirstStep && <button type="button" onClick={back} >Back</button> }
                    { !isLastStep ? <button type="submit">Next</button> : <button type="submit">Submit</button> }
                </div>

            </form>
            <div className={style.errorMessage}>{msg && <p>{msg}</p>}</div>
        </div>
    )
}
