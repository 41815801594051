import React,  { useState } from "react";
import Home from "./Home";


const HomeContainer = (props) => {

    return <Home/>
}


export default HomeContainer;