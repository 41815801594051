import React from 'react'
import ProfileComponentContainer from '../../components/Profile/ProfileComponentContainer';



const Profile = (props) => {

    return (
        <ProfileComponentContainer />
    )
}


export default Profile;